module.exports = {
  IOS: navigator.userAgent.match(/Mac OS/),
  IPHONE: navigator.userAgent.indexOf('iPhone') > -1,
  ANDROID: navigator.userAgent.indexOf('Android') > -1 ||
    navigator.userAgent.indexOf('Linux') > -1,
  BROWSER: {
    iPhone: navigator.userAgent.indexOf('iPhone') > -1,
    android: navigator.userAgent.indexOf('Android') > -1 ||
      navigator.userAgent.indexOf('Linux') > -1,
    // isclient: navigator.userAgent.indexOf('wwyq') > -1,
    ios: navigator.userAgent.match(/Mac OS/), // ios
    width: window.innerWidth,
    height: window.innerHeight,
    clientIp: null,
    isclient: navigator.userAgent.indexOf('yhh') > -1 ||
      navigator.userAgent.indexOf('wwyq') > -1 || navigator.userAgent.indexOf('yyyq') > -1|| navigator.userAgent.indexOf('YYbeauy') > -1,
    isYyyqClient: navigator.userAgent.indexOf('wwyq') > -1,
    isYhhClient: navigator.userAgent.indexOf('yhh') > -1,
    witchRoute: navigator.userAgent.indexOf('wwyq') > -1 ? 'winwallet' : navigator.userAgent.indexOf('yhh') > -1 ? 'winhuahua' : 'winquick'
  },
  JumpNextPage: function (url, jsParam) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'jumpNextPage', {
        url: url,
        jsParam: jsParam || ''
      })
    })
  },
  JumpCloseNextPage: function (url) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'jumpAndClosePage', { url: url })
    })
  },
  BACK: function (num) {
    document.addEventListener('WinJSBridgeReady', function () {
      if (window.WinJSBridge) {
        if (num != null) {
          window.WinJSBridge.call('controller', 'back', { index: -num })
        }
      } else {
        if (num != null) {
          window.history.go(-num)
        } else {
          window.history.go(-1)
        }
      }
    })
  },
  CAMERA: function (fn) {
    // ios拍照
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('photo', 'pickphotoes', { camera: true }, (resp) => {
        if (fn) fn(resp)
        // console.log(resp.response.images[0])
      })
    })
  },
  iOS_PICKPHOTO: function (fn) {
    // ios照片选择
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('photo', 'pickphotoes', {
        camera: false,
        maxCanPicked: 9,
        quality: 1
      }, (resp) => {
        if (fn) fn(resp)
        // console.log(resp.response.images[0])
      })
    })
  },
  PHOTO_CAMERA_MORE: function(fn) {
    document.addEventListener("WinJSBridgeReady", function() {
        window.WinJSBridge.call(
            "tools",
            "pickPhotos", {
                'camera': false,
                'maxCanPicked': 1
            },
            function(resp) {
                if (fn) fn(resp);
            }
        );
    });
},
  // PHOTO_CAMERA_MORE: function (fn) {
  //   document.addEventListener('WinJSBridgeReady', function () {
  //     window.WinJSBridge.call(
  //       'mediaPhoto',
  //       'cameraOrPhoto', {
  //         camera: false,
  //         photoNum: 1
  //       },
  //       function (resp) {
  //         if (fn) fn(resp)
  //       }
  //     )
  //   })
  // },
  COMMONDATA: function (fn, err) {
    // 获取设备信息
    // if (this.BROWSER.isclient) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('params', 'getPublicParams', function (
        resp
      ) {
        if (resp.code === 0) {
          // 成功
          if (resp.response != null) {
            fn && fn(resp.response)
            window.dmsIp = resp.response.clientIp
          }
        } else {
          fn(resp)
        }
      })
    })
    // } else {
    //     fn({"appSourceId": "androidTest", "device": "android", "mobileType": "vivo X20A", "deviceId": "e9b5ea9aba83049067ef515a1c6f9910", "wifiMac": "02:00:00:00:00:00", "token": "1f659317a9524f1c88ec79ac1e7d6a7e", "mac": "70:47:E9:E6:71:E9", "clientProvince": "浙江省", "appName": "YYYQ", "lng": "120.16631", "pushId": "ME6gtftbwrCQ6iM2qRQoEO0Wk6xXa+i2N95AXRR6Fz3L4XLBcTuY1eKpW48Vze5d", "clientIp": "10.20.2.83", "appVersion": "1.1.9", "imei": "868082035004794", "clientRegion": "浙江省杭州市下城区杨六堡路390号靠近杭州市城建档案馆", "osVersion": "7.1.1", "deviceName": "vivo", "clientCity": "杭州市", "lat": "30.300956"});
    // }
  },
  detectionFrontIDCard: function (fn) {
    // 身份证正面
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('faceid', 'detectionFrontIDCard', function (resp) {
        if (resp.code === 0) {
          // 成功
          if (resp.response != null) {
            fn(resp.response)
          }
        } else {
          fn(resp.code)
        }
      })
    })
  },

  detectionBackIDCard: function (fn) {
    // 身份证背面
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('faceid', 'detectionBackIDCard', function (resp) {
        if (resp.code === 0) {
          // 成功
          if (resp.response != null) {
            fn(resp.response)
          }
        } else {
          fn(resp.code)
        }
      })
    })
  },
  getLocParams: function (name) {
    var href = window.location.href
    var subIndex = href.indexOf('?')
    var paramsObj = {}
    if (subIndex !== -1) {
      var params = href.substr(subIndex + 1)
      var paramsMany = params.indexOf('&')
      if (paramsMany !== -1) {
        var paramsArr = params.split('&')
        paramsArr.forEach(function (item, index) {
          paramsObj[item.split('=')[0]] = item.split('=')[1]
        })
      } else {
        paramsObj[params.split('=')[0]] = params.split('=')[1]
      }
    }

    if (paramsObj.hasOwnProperty(name)) {
      return paramsObj[name]
    } else {
      return null
    }
  },
  // 组件名 moxie  方法moxieAuth   入参 authLogId:'1235444587',moXieType:'qq',riskUserId:'89611122'
  MX_AUTH: function (data) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('moxie', 'moxieAuth', data)
    })
  },
  // 认证
  JUMP_USER_AUTH: function () {
    var brUrl = this.BROWSER.witchRoute + '://page/userauth'
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'controller',
        'jumpNativePage', { url: brUrl },
        function (resp) {
          if (resp.code !== 0) {
            console.log(JSON.stringify(resp))
          }
        }
      )
    })
  },
  // // 清除cookie
  // REMOVEALLCOOKIE: function () {
  //     if (!APP.ANDROID) {
  //         return;
  //     }
  //     document.addEventListener("WinJSBridgeReady", function () {
  //         window.WinJSBridge.call("webview", "removeAllCookie");
  //     });
  // },
  // ios弹动橡皮筋效果
  SETBOUNCE: function (flag) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'controller',
        'setBounce', { enable: flag },
        function (resp) {
        }
      )
    })
  },
  // android 数据上报（短信，app安装列表，通话记录）
  COLLECTDATAREPORT: function (flag) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('collectdatareport', 'report', function (
        resp
      ) {
      })
    })
  },
  GETLOCATIONINFO: function (fn) {
    // if (process.env.NODE_ENV == "development") {
    //   console.log(222);
    //   fn(JSON.parse("{\"clientCity\":\"南宁市\",\"clientProvince\":\"广西壮族自治区\",\"clientRegion\":\"广西壮族自治区南宁市青秀区枫林路17-1号靠近蒙特梭利教育园地\",\"lat\":\"22.827034\",\"lng\":\"108.410495\"}"));
    //   return;
    // }
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('location', 'getLocation', function (resp) {
        try {
          if (resp) {
            const response = resp.response
            if (Object.keys(resp.response).length == 0) {
              return
            }
            const resultCode = response.resultCode
            if (resultCode == 1 || resultCode == 99) {
              APP.ALERT('温馨提示', '请打开定位权限')
              return
            }
          }
          if (fn) fn(resp.response)
        } catch (error) {
          APP.ALERT('GEO信息获取异常，请联系管理员')
        }
      })
    })
  },
  COMMONDATA: function () {
    // if (this.BROWSER.isclient) {

    //     // fn({ "osVersion": "12.1.4", "clientIp": "10.20.3.246", "mobileType": "iPhoneX", "clientCity": "杭州市", "deviceId": "25e495f1181bce2763171bbf1cf70711", "clientProvince": "浙江省", "appSourceId": "1", "deviceName": "盈火测试机iPhoneX", "wifiMac": "", "carrierOperator": "无运营商", "appVersion": "1.0.5", "lng": "120.161619", "clientRegion": "中国浙江省杭州市下城区绍兴路390号", "pushId": "1a1018970ae41676c7e", "screenHeight": "812", "resolution": "1125*2436", "mac": "02:00:00:00:00:00", "appName": "MEILI", "token": "54d1f737d667e24af85ea065cf21908fc846d887", "networkStatus": "Wifi", "screenWidth": "375", "lat": "30.303181", "tId": "eyJ0b2tlbklkIjoiVldCTDltdEZkc1JQWitZQzBKQWVUbjVOcUNNcjdueHozTGJTNUFMa1wvOTlVK0ZqRTlvK3RZUE16N2tYXC81ZDVWU1loVHhKU1EzaW9kOFhEVDNOekxLdz09Iiwib3MiOiJpT1MiLCJwcm9maWxlVGltZSI6ODIsInZlcnNpb24iOiIzLjEuNyJ9", "device": "ios", "idfa": "B1B53A1D-0E0F-405F-B7D6-BEEFFE318C99" });
    // } else {
    //     fn({ "osVersion": "12.1.4", "clientIp": "10.20.3.246", "mobileType": "iPhoneX", "clientCity": "杭州市", "deviceId": "25e495f1181bce2763171bbf1cf70711", "clientProvince": "浙江省", "appSourceId": "1", "deviceName": "盈火测试机iPhoneX", "wifiMac": "", "carrierOperator": "无运营商", "appVersion": "1.0.5", "lng": "120.161619", "clientRegion": "中国浙江省杭州市下城区绍兴路390号", "pushId": "1a1018970ae41676c7e", "screenHeight": "812", "resolution": "1125*2436", "mac": "02:00:00:00:00:00", "appName": "MEILI", "token": "54d1f737d667e24af85ea065cf21908fc846d887", "networkStatus": "Wifi", "screenWidth": "375", "lat": "30.303181", "tId": "eyJ0b2tlbklkIjoiVldCTDltdEZkc1JQWitZQzBKQWVUbjVOcUNNcjdueHozTGJTNUFMa1wvOTlVK0ZqRTlvK3RZUE16N2tYXC81ZDVWU1loVHhKU1EzaW9kOFhEVDNOekxLdz09Iiwib3MiOiJpT1MiLCJwcm9maWxlVGltZSI6ODIsInZlcnNpb24iOiIzLjEuNyJ9", "device": "ios", "idfa": "B1B53A1D-0E0F-405F-B7D6-BEEFFE318C99" });
    // }
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV == 'development') {
        resolve({
          osVersion: '10.3.2',
          clientIp: '10.20.2.175',
          mobileType: 'iPhone 6',
          clientCity: '杭州市',
          deviceId: '',
          clientProvince: '浙江省',
          appSourceId: '1',
          deviceName: 'iPhone',
          wifiMac: '10.20.2.175',
          carrierOperator: '中国电信46011',
          appVersion: '2.0.9',
          lng: '1',
          clientRegion: '中国浙江省杭州市下城区绍兴路390号',
          pushId: '',
          screenHeight: '667',
          resolution: '750*1334',
          resultCode: 0,
          mac: '02:00:00:00:00:00',
          appName: 'MEILI',
          token: '3e9434b665b7e3b1b3d2132fcf6fc4f827a1327c',
          networkStatus: 'Wifi',
          resultMessage: '执行成功',
          screenWidth: '375',
          lat: '2',
          tId: 'eyJ0b2tlbklkIjoiY0FPV1wvdGw1TkJXbW5Sdk9scDY2UWFtNmxNVkdHTFhNQ05EVnA1MmJqOXVDYzJtVVwvMUhLcEp5S244Nmh0aEpRSzhTczZydzh2ejRZTUFcL2FzQm52Umc9PSIsIm9zIjoiaU9TIiwicHJvZmlsZVRpbWUiOjc4MSwidmVyc2lvbiI6IjMuMS43In0=',
          device: 'ios',
          idfa: 'DE428831-D634-4FBB-A59D-8C019DA54487'
        })
        return
      }
      document.addEventListener('WinJSBridgeReady', function () {
        window.WinJSBridge.call('params', 'getPublicParams', function (
          resp
        ) {
          if (resp.code === 0) {
            // 成功
            if (resp.response != null) {
              resolve(resp.response)
            }
          } else {
            // 失败
            APP.TOAST('获取基础信息失败', -1)
          }
        })
      })
      // resolve({"osVersion": "12.1.4", "clientIp": "10.20.3.246", "mobileType": "iPhoneX", "clientCity": "杭州市", "deviceId": "25e495f1181bce2763171bbf1cf70711", "clientProvince": "浙江省", "appSourceId": "1", "deviceName": "盈火测试机iPhoneX", "wifiMac": "", "carrierOperator": "无运营商", "appVersion": "1.0.5", "lng": "120.161619", "clientRegion": "中国浙江省杭州市下城区绍兴路390号", "pushId": "1a1018970ae41676c7e", "screenHeight": "812", "resolution": "1125*2436", "mac": "02:00:00:00:00:00", "appName": "MEILI", "token": "54d1f737d667e24af85ea065cf21908fc846d887", "networkStatus": "Wifi", "screenWidth": "375", "lat": "30.303181", "tId": "eyJ0b2tlbklkIjoiVldCTDltdEZkc1JQWitZQzBKQWVUbjVOcUNNcjdueHozTGJTNUFMa1wvOTlVK0ZqRTlvK3RZUE16N2tYXC81ZDVWU1loVHhKU1EzaW9kOFhEVDNOekxLdz09Iiwib3MiOiJpT1MiLCJwcm9maWxlVGltZSI6ODIsInZlcnNpb24iOiIzLjEuNyJ9", "device": "ios", "idfa": "B1B53A1D-0E0F-405F-B7D6-BEEFFE318C99"});
    })
  },
  // 二维码回调
  // SCAN_EVENT: function (fn,type) {
  //   document.addEventListener("WinJSBridgeReady", function () {
  //     document.removeEventListener("ScanEvent", callback(fn),true);
  //     document.addEventListener("ScanEvent", callback(fn),true);
  //   });
  // },
  // 二维码回调
  SCAN_EVENT: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      document.addEventListener('ScanEvent', fn, true)
    })
  },

  // 银行卡回调
  BANK_EVENT: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      document.addEventListener('BankCardInfoEvent', fn)
    })
  },
  CLOSE_WEBVIEW: function (index) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'leftX', {
        index: index,
        enable: true
      })
    })
  },
  INIT_ADDRESS: function (fn) {
    window.WinJSBridge.call('notification', 'actionAddress', function (
      resp
    ) {
      if (fn) fn(resp)
    })
  },
  // 魔蝎SDK 参数{authLogId:'1235444587',moXieType:'qq',reqNo:'89611122'}
  USEMOXIESDK: function (obj) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('moxie', 'moxieAuth', obj, function (resp) {
        if (resp.code === 0) {
          // 成功
          if (resp.response != null) {
          }
        } else {
          // 失败
          APP.TOAST('魔蝎接口调用失败', -1)
        }
      })
    })
  },
  // 调银行卡
  JUMP_BANK_LIST: function () {
    var brUrl = this.BROWSER.witchRoute + '://page/bankcard?status=0'
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'jumpNativePage', { url: brUrl })
    })
  },
  DRAGBACKENABLE: function (flag) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('webview', 'dragbackenable', { enable: flag })
    })
  },
  // 扫一扫
  JUMP_SCAN: function () {
    var brUrl = 'meilly://app.meilly.cn/scanner/showscan'
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'jumpNativePage', { url: brUrl })
    })
  },
  // 通讯录
  GET_MAIL: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('contact', 'open', function (resp) {
        if (resp) {
          const response = resp.response
          if (Object.keys(resp.response).length == 0) {
            return
          }
          const resultCode = response.resultCode
          if (resultCode == 1) {
            APP.ALERT('温馨提示', '请打开通讯录权限')
            return
          }
          if (resultCode == 99) {
            return
          }
        }
        if (fn) fn(resp)
      })
    })
  },
  FACEID: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('faceid', 'livingDetection', function (resp) {
        if (fn) fn(resp)
      })
    })
  },

  JUMP_TO: function (url, jsParam) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'jumpNextPage', {
        url: url,
        jsParam: jsParam || ''
      })
    })

    // 跳转到
    // try {
    //   if (this.BROWSER.isclient) {
    //     console.log('hh')
    //     document.addEventListener('WinJSBridgeReady', function () {
    //       window.WinJSBridge.call('controller', 'jumpNextPage', {
    //         url: url,
    //         jsParam: jsParam || ''
    //       })
    //     })
    //   } else {
    //     console.log(navigator.userAgent)
    //     window.location.href = url
    //   }
    // } catch (err) {
    //   console.log(err)
    // }
  },
  JUMP_AND_CLOSEPAGE_NATIVE: function (des) {
    // 跳转到
    var brUrl = this.BROWSER.witchRoute + '://page/' + des
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'jumpAndClosePage', {
        url: brUrl,
        animate: true
      })
    })
  },

  JUMP_AND_CLOSEPAGE: function (des) {
    // 跳转到
    if (this.BROWSER.isclient) {
      document.addEventListener('WinJSBridgeReady', function () {
        window.WinJSBridge.call('controller', 'jumpAndClosePage', {
          url: url,
          animate: true
        })
      })
    } else {
      window.location.href = url
    }
  },

  JUMP_HASH: function (hash) {
    var url = 'http://' + window.location.host + hash
    // 跳转到
    if (this.BROWSER.isclient) {
      document.addEventListener('WinJSBridgeReady', function () {
        window.WinJSBridge.call('controller', 'jumpNextPage', {
          url: url,
          animate: true
        })
      })
    } else {
      window.location.href = url
    }
  },

  SAVE_CACHE: function (key, data) {
    // if (!level) level = 1;
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('cache', 'save', {
        key: key,
        data: data
      })
    })
  },
  GET_CACHE: function (keys) {
    // function takeLongTime () {
    return new Promise((resolve, reject) => {
      try {
        document.addEventListener('WinJSBridgeReady', function () {
          window.WinJSBridge.call(
            'cache',
            'get', { key: keys },
            function (resp) {
              // alert(JSON.stringify(resp));
              resolve(resp)
            }
          )
        })
      } catch (error) {
        alert(error)
      }
    })
    // }

    // async function test () {
    //     const v = await takeLongTime();
    //     return v;
    // }
    // return test();
  },
  CHECKTAUTH: function (action, thirdChannel, productNo, fn) {
    if (!action || !thirdChannel || !productNo) return
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'register',
        'registerDispatch', {
          productNo: productNo,
          thirdChannel: thirdChannel,
          action: action
        },
        function (resp) {
          if (fn) {
            fn(resp)
          }
        }
      )
    })
  },
  JUMP: function (url) {
    this.JUMP_TO(url)
  },
  LOGIN_EVENT: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      document.addEventListener('LoginEvent', fn)
    })
  },
  LOGIN_EVENT_Remove: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      document.removeeventlistener('LoginEvent', fn)
    })
  },
  // 新版数据上报安卓
  // @JsInject(module = "collectdata", method = "getAppList")
  // @JsInject(module = "collectdata", method = "getCallLogList")
  // @JsInject(module = "contact", method = "open")
  // @JsInject(module = "collectdata", method = "getSmsList")
  REPORTALL: function () {
    // let getCallLogList = new Promise((resolve, reject) => {
    //     APP.REPORT('getCallLogList', (res) => {
    //         alert(1);
    //         resolve(res);
    //     });
    // });
    // let getSmsList = new Promise((resolve, reject) => {
    //     APP.REPORT('getSmsList', (res) => {
    //         resolve(res);
    //     });
    // });
    // let getAppList = new Promise((resolve, reject) => {
    //     APP.REPORT('getAppList', (res) => {
    //         resolve(res);
    //     });
    // });
    // // 2.通话记录 3.短信记录 4.APP安装列表
    // Promise.all([getCallLogList, getSmsList, getAppList])
    //     .then((result) => {
    //         alert(JOSN.stringify(result));
    //         for (let i = 2; i < result.length; i++) {
    //             report.collectdata({
    //                 collectType: i,
    //                 collectJsonData: [result[i]]
    //             }).then((item) => {
    //                 alert(1);
    //             }).catch(() => {
    //                 alert(2);
    //             });
    //         }
    //     }).catch((error) => {
    //         console.log(error);
    // });
  },
  REPORT: function (type, fn) {
    if (!APP.IOS) {
      document.addEventListener('WinJSBridgeReady', function () {
        window.WinJSBridge.call('collectdata', type, fn)
      })
    }
  },
  pageClose: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('marketing', 'close')
    })
  },
  BACK: function (num) {
    document.addEventListener('WinJSBridgeReady', function () {
      if (window.WinJSBridge) {
        if (num != null) {
          window.WinJSBridge.call('controller', 'back', { index: num })
        }
      } else {
        if (num != null) {
          history.go(num)
        } else {
          history.go(-1)
        }
      }
    })
  },
  BACK_PAGE: function (page) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'back', { page: page })
    })
  },
  BACK_LISTEN: function (fn) {
    // 返回监听
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'backListen', { enable: true })

      document.addEventListener('clickback', function () {
        if (fn) fn()
      })
    })
  },
  HIDE_BACK: function (hide) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('webview', 'hideBack', { hide: hide })
    })
  },
  PHOTO_CAMERA: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'mediaPhoto',
        'cameraOrPhoto', {
          camera: true,
          photoNum: 1
        },
        function (resp) {
          if (fn) fn(resp)
        }
      )
    })
  },
  PHOTO_CAMERA_MORE: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'mediaPhoto',
        'cameraOrPhoto', {
          camera: true,
          photoNum: 8
        },
        function (resp) {
          if (fn) fn(resp)
        }
      )
    })
  },
  MID_MENU: function (menus, index, handle) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call2(
        'controller',
        'setMenuTitle', {
          selected: index,
          items: menus
        },
        handle
      )
    })
  },
  RIGHT_MENU: function (title, url) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'showOptionMenu', {
        title: title,
        onclick: 'APP.JUMP_TO("' + url + '")'
      })
    })
  },
  RIGHT_SHARE: function (title, obj) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'showOptionMenu', {
        title: title,
        onclick: 'APP.SHARE(' + JSON.stringify(obj) + ')'
      })
    })
  },
  RIGHT_SHARE_IMG: function (title, obj) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'showOptionMenu', {
        title: title,
        onclick: 'APP.SHARE_IMG(' + JSON.stringify(obj) + ')'
      })
    })
  },
  RIGHT_MENU_FN: function (title, FN) {
    document.addEventListener('WinJSBridgeReady', function () {
      var random = ('___callback___' + Math.random()).replace('.', '')
      window[random] = function () {
        FN()
      }

      window.WinJSBridge.call('controller', 'showOptionMenu', {
        title: title,
        onclick: random + '()'
      })
    })
  },
  JumpNative: function (des) {
    var brUrl = this.BROWSER.witchRoute + des
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'controller',
        'jumpNativePage', { url: brUrl },
        function (resp) {
          if (resp.code !== 0) {
            console.log(JSON.stringify(resp))
          }
        }
      )
    })
  },
  JumpNativeFull: function (des) {
    var brUrl = des
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'controller',
        'jumpNativePage', { url: brUrl },
        function (resp) {
          if (resp.code !== 0) {
            console.log(JSON.stringify(resp))
          }
        }
      )
    })
  },
  JumpNativePage: function (des) {
    var brUrl = this.BROWSER.witchRoute + '://page/' + des
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'controller',
        'jumpNativePage', { url: brUrl },
        function (resp) {
          if (resp.code !== 0) {
            console.log(JSON.stringify(resp))
          }
        }
      )
    })
  },
  PAGE_WILL_LOAD: function (handle) {
    document.addEventListener('WinJSBridgeReady', function () {
      document.addEventListener('onpageshow', handle)
    })
  },
  REFRESH: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'refresh', { pullRefresh: false })
    })
  },
  SET_REFRESH: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'setPullRefresh', { enable: true })
    })
  },
  ALERT: function (title, content) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'notification',
        'alert', {
          title: title,
          message: content
        },
        function (resp) {
        }
      )
    })
  },
  DRAG_DISABLE: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'dragBackEnable', { enable: false })
    })
  },
  DRAG_ENABLE: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'dragBackEnable', { enable: true })
    })
  },
  ALERT_FN: function (title, content, callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'notification',
        'alert', {
          title: title,
          message: content
        },
        function (resp) {
          if (callback) callback(resp)
        }
      )
    })
  },
  // 组件 controller   方法  isShowTitleBar   入参  enable  (boolean类型)
  SET_HEADER: function (isShow) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'isShowTitleBar', { enable: isShow })
    })
  },
  LOGIN: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('login', 'login', function (resp) {
      })
    })
  },
  SET_TITLE: function (title, callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'setTitle', {
        title: title,
        onclick: 'callback()'
      })
    })
  },

  CONFIRM: function (title, msg, callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'notification',
        'confirm', {
          title: title,
          message: msg,
          buttons: ['取消', '确定']
        },
        function (resp) {
          if (callback) callback(resp)
        }
      )
    })
  },

  CONFIRM_BTN: function (title, msg, btns, callbackFn) {
    // if (this.BROWSER.isclient) {

    // } else {
    //     if (confirm(msg)) {
    //         if (callbackFn) {
    //             callbackFn({
    //                 response: {
    //                     buttonIndex: 1
    //                 }
    //             });
    //         }
    //     }
    // }
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'notification',
        'confirm', {
          title: title,
          message: msg,
          buttons: btns
        },
        function (resp) {
          if (callbackFn) callbackFn(resp)
        }
      )
    })
  },
  // CACEHACTION: function () {
  //     return {
  //         setItem: function (key, data) {
  //             window.WinJSBridge.call('cache', 'save', {"key": key, "data": JOSN.stringify(data)});
  //         },
  //         getItem: function (key, data) {
  //             window.WinJSBridge.call('cache', 'get', {"key": key}, function (v) {
  //                 return v;
  //             });
  //         },
  //     };
  // },
  NETWORK_STATUS: function () {
    window.WinJSBridge.call('device', 'networkStatus', function (resp) {
      if (resp.response.status === 0) {
        APP.TOAST('当前网络不可用，请检查您的网络设置')
      } else {
        APP.ALERT('', '服务器正忙，请稍后重试')
      }
    })
  },
  TOAST: function (message, type) {
    if (!message) {
      return
    }
    // if (this.BROWSER.isclient) {

    // } else {
    //     alert(message);
    // }
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('notification', 'toast', {
        message: message.toString(),
        type: type,
        offSet: 100,
        center: true
      })
    })
  },
  OPENWEBSITE: function (url) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'openURLOutside', { url: url })
    })
  },
  APP_LIST: function (fun) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('installApp', 'apps', function (resp) {
        fun(resp)
      })
    })
  },
  IS_INSTALL_APP: function (appId, fun) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'installApp',
        'isInstall', { appId: appId },
        function (resp) {
          fun(resp)
        }
      )
    })
  },
  CHOOSE_ADDRESS: function (fun, aid) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('address', 'chooseAddress', function (resp) {
        fun(resp)
      })
    })
  },
  TOBONUS: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('mine', 'toBonus', {})
    })
  },
  SENDCOMMAND: function () {
    if (window.browser.iPhone || window.browser.ipad) {
      var iframeElem = document.createElement('iframe')
      iframeElem.setAttribute('src', url)
      iframeElem.setAttribute('style', 'display:none;')
      iframeElem.setAttribute('height', '0px')
      iframeElem.setAttribute('width', '0px')
      iframeElem.setAttribute('frameborder', '0')
      document.body.appendChild(iframeElem)
      iframeElem.parentNode.removeChild(iframeElem)
      iframeElem = null
    } else {
      if (typeof window.andcall === 'undefined') {
        window.location.href = url
      } else {
        window.andcall.sendmsg(url)
      }
    }
  },
  SHARE: function (obj) {
    var $this = this
    // 转换指定的版本字符串
    var getVersionNum = function (v) {
      var split = v.split('.')
      var num = 0
      var len = split.length
      for (var i = 0; i < len; i++) {
        var tmp = Number(split[i]) * Math.pow(10000, len - i - 1)
        num += tmp
      }
      return num
    }
    // 获取当前版本正则
    var m = /WinClient\/(\d+\.\d+(?:\.\d+)?)/.exec(
      window.navigator.userAgent
    )
    var currentVersion = '0.0.0'
    if (m) currentVersion = m[1]

    if (getVersionNum(currentVersion) >= getVersionNum('1.3.2')) {
      document.addEventListener('WinJSBridgeReady', function () {
        window.WinJSBridge.call(
          'share',
          'share', {
            title: obj.title,
            shareItems: obj.channel,
            shareTitle: '分享到',
            content: obj.title,
            url: obj.url,
            thumbIamge: obj.imgBase64,
            thumbImageUrl: obj.imgUrl,
            recipients: '',
            specialContent: {
              sms: obj.sms + obj.url,
              qqhy: obj.qqhy,
              sinawb: obj.sinawb + obj.url,
              tcwb: obj.tcwb + obj.url,
              wxhy: obj.wxhy,
              wxpyq: obj.wxpyq,
              qqhy_url: obj.url,
              wx_url: obj.url
            }
          },
          function (resp) {
            var result = JSON.stringify(resp)
            var resultObj = JSON.parse(result)
            var sItem = resultObj.response.shareItem
            var shareObj = {
              sms: '短信分享成功',
              wxhy: '分享到微信好友成功',
              wxpyq: '分享到朋友圈成功',
              sinawb: '分享到新浪微博成功'
            }
            var shareErrObj = {
              sms: '短信分享失败',
              wxhy: '分享到微信好友失败',
              wxpyq: '分享到朋友圈失败',
              sinawb: '分享到新浪微博失败'
            }
            if (APP.IOS) {
              if (resultObj.response.resultCode === '0') {
                APP.TOAST(shareObj[sItem], 0)
              } else if (resultObj.code === '0') {
                APP.TOAST(resultObj.message, 1)
              }
            } else {
              var _code = resultObj.response.resultCode

              if (_code === '1') {
                // 失败
                APP.TOAST(shareErrObj[sItem], 1) // 错误
              } else if (_code === '0') {
                // 成功
                APP.TOAST(shareObj[sItem], 0) // 对勾
              } else {
                APP.TOAST('取消分享', 2)
              }
            }

            if (config.signFlag) {
              $.ajax({
                url: '/activity/share/click.do',
                data: { actId: obj.id },
                type: 'GET',
                success: function () {
                },
                error: function () {
                  // alert("服务端繁忙");
                }
              })
            }
          }
        )
      })
    } else {
      var title = obj.title // 标题
      var smsTxt = obj.sms + obj.url // 短信
      var wxhyTxt = obj.wxhy // 微信好友
      var wxpyqTxt = obj.wxpyq // 微信朋友圈
      var sinawbTxt = obj.sinawb + obj.url // 新浪微博
      var qqhyTxt = obj.qqhy // QQ好友
      var tcwbTxt = obj.tcwb + obj.url // 腾讯微博
      var url = obj.url
      var logodata = obj.imgBase64
      var imageUrl = obj.imgUrl

      var specialContent =
        '{' +
        '"sms":"' +
        smsTxt +
        '", ' +
        '"qqhy":"' +
        qqhyTxt +
        '", ' +
        '"sinawb":"' +
        sinawbTxt +
        '", ' +
        '"tcwb":"' +
        tcwbTxt +
        '", ' +
        '"wxhy": "' +
        wxhyTxt +
        '",' +
        '"wxpyq": "' +
        wxpyqTxt +
        '",' +
        '"qqhy_url":"' +
        url +
        '", "wx_url":"' +
        url +
        '"}'
      // var param = '{"title":"' + title + '", "content":"' + content + '","url":"' + url + '", "thumbIamge":"' + logodata + '", "thumbImageUrl":"' + imageUrl + '", "specialContent": ' + specialContent + ' ,"shareItems":[] }';
      // var content = encodeURIComponent(param);
      // APP中分享
      this.SENDCOMMAND('yylc&share&' + content)
    }
  },
  SHARE_IMG: function (obj) {
    var shareContent = {
      imageUrl: obj.shareImgUrl,
      shareTitle: obj.shareTitle
    }
    window.WinJSBridge.call('share', 'shareImage', shareContent, function (
      resp
    ) {
      var result = JSON.stringify(resp)
    })
  },
  LOADING: function (text) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'showLoading', { text: text })
    })
  },
  CLOSE_LOADING: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('controller', 'hideLoading')
    })
  },
  ISLOGIN: function (fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('login', 'isLogin', function (resp) {
        fn(resp)
      })
    })
  },
  CHECK_SECRET: function (title, money, point, params, fn) {
    if (!params) return

    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call(
        'tradepwd',
        'back', {
          old: true,
          title: title,
          hideIcon: false,
          amount: money,
          point: point,
          others: params
        },
        function (resp) {
          var result = JSON.stringify(resp)
          if (fn) fn(resp)
        }
      )
    })
  },
  DEVICE: function (callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('device', 'deviceInfo', function (resp) {
        if (callback) callback(resp)
      })
    })
  },
  IS_SUPPORT_API: function (apiName) {
    if (this.DEVICE_INFO == null) {
      return false
    }
    var device = this.DEVICE_INFO.device
    var version = this.DEVICE_INFO.appVersion
    if (this.DEVICE_INFO.info != null) {
      device = this.DEVICE_INFO.info.device
      version = this.DEVICE_INFO.info.appVersion
    }
    device = device.toLowerCase()

    function computer (v) {
      var varry = v.split('.')
      var carry = version.split('.')

      if (Number(carry[0]) < Number(varry[0])) {
        return false
      } else if (Number(carry[1]) < Number(varry[1])) {
        return false
      } else if (Number(carry[2]) < Number(varry[2])) {
        return false
      }
      return true
    }

    function versionAndroidOrIOS (androidVersion, iosVersion) {
      if (device === 'android') {
        return computer(androidVersion)
      } else if (device === 'ios') {
        return computer(iosVersion)
      }
    }

    switch (apiName) {
      case 'address':
        return versionAndroidOrIOS('4.2.8.0', '2.12.0')
    }
    return false
  },
  livingDetection: function (data,fn) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('faceid', 'livingDetection', data,function (resp) {
        if (resp.code === 0) { // 成功
          if (resp.response) {
            fn(resp)
          }
        } else {
        }
      })
    })
  }

}
