import API from '@/server/api'

const state = {
  mobile: ''
}

const getters = {
  mobile: (state) => state.mobile
}

const mutations = {
  setMobile (state, mobile) {
    state.mobile = mobile
  }
}
const actions = {
  async login ({ commit }, user) {
    try {
      user.channel='ML_H5'
      const result = await API.isLogin(user)
      if (result.success) commit('setMobile', user.phone)
      return result
    } catch (e) {
      console.log(e, '错误信息h')
      return e
    }
  },
  // 设置用户
  setMobile ({ commit }, user) {
    commit('setMobile', user.phone)
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}
