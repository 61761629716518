<template>
  <div class="bill-container">
    <div class="bill-header">
      <div class="header-top">
        <p>
          <span class="nowMoney">金银豆</span>
          <span class="loanNum">借款期数:{{loanresult.loanLimit}}期 | 借款时间:{{loanresult.gmtCreatedStr}}</span>
        </p>
        <p class="protocalNum">
          <span>借款编号：{{loanresult.loanNo}}</span>
          <span class="seeLoan" @click="seeProtocol">查看协议</span>
        </p>
      </div>
      <div class="header-bottom">
        <p class="loanMoney">借款金额(元)</p>
        <p class="redMoney">{{ $filters.formatMoney(loanresult.loanAmt,2) }}</p>
      </div>
    </div>
    <!-- 待还期数 -->
    <div class="awaitLoan">
      <p class="loanTitle">
        <span class="square"></span>
        <span class="titleText">待还期数</span>
      </p>
      <div class="everyMoney">
        <div class="everyItem" v-for="(item,index) in loanresult.planList" :key="index">
          <p class="item-top">
            <span class="number">第<i>{{item.planIndex}}</i>期</span>
            <span class="dateTime">{{item.planDateStr}}</span>
          </p>
          <p class="item-middle">
            <span class="moneyNum">{{item.planAmt}}</span>
            <span v-if="item.statusDes=='已逾期'" class="loanStatus1">已逾期</span>
            <span v-if="item.statusDes=='待还款'" class="loanStatus2">待还款</span>
            <span v-if="item.statusDes=='未到还款日'" class="loanStatus3">未到还款日</span>
          </p>
          <p class="nowText">当前应还(元)</p>
        </div>

      </div>
    </div>
    <div class="btn" @click="paymentamount">去还款</div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { GetUrlParams, isEmptyObj, routerTag } from '../../utils/index'
import Moment from 'moment'
import { Toast } from 'vant'
import API from '../../server/api'
import APP from '@/utils/App'

export default {
  name: 'Index',

  setup () {
    // 设置标题Title
    const state = reactive({
      loanNo: '',
      loanresult: {}
    })
    // 初始化信息
    const init = async () => {
      const result = await API.getloanInfo({loanNo:state.loanNo})
      console.log(result, '哈哈哈')
      state.loanresult = result
    }
    const seeProtocol = () => {
      APP.JUMP_TO(`${routerTag}loanProtocol?num=3`)
    }
    // 去还款
    const paymentamount = () => {
      APP.JUMP_TO(`${routerTag}paymentamount`)
    }
    onMounted(() => {
      const urlParam=GetUrlParams();
      APP.SET_TITLE('账单详情')
      state.loanNo = urlParam.loanNo;
      window.localStorage.setItem('loanNos',state.loanNo)
      init()
    })
    return {
      seeProtocol,
      paymentamount,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped lang='less' src='./index.less'></style>
