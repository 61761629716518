import { createRouter, createWebHashHistory } from 'vue-router'
import APP from '@/utils/App'
// import Index from '../views/Index/index'
import BillDetail from '../views/BillDetail/index.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   desc: '首页',
  //   component: () => import('../views/Index/index.vue')
  // },
  {
    path: '/',
    name: 'Index',
    desc: '首页',
    component: () => import('../views/Index/newpages.vue')
  },
  {
    path: '/billDetail',
    name: 'BillDetail',
    desc: '账单详情',
    component: BillDetail
  },
    {
      path: '/Bill',
      name: 'Bill',
      desc: '账单',
       component: () => import('../views/Bill/index.vue')
    },
  {
    path: '/loanProtocol',
    name: 'LoanProtocol',
    desc: '借款协议',
    component: () => import('../views/LoanProtocol/index.vue')
  },
  {
    path: '/Payrepayment',
    name: 'Payrepayment',
    desc: '支付',
    component: () => import('../views/Payrepayment/index.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    desc: '支付成功',
    component: () => import('../views/payResult/index.vue')
  },

  {
    path: '/auth',
    name: 'Auth',
    desc: '实名认证',
    component: () => import('../views/auth/index.vue')
  },
    {
      path: '/myauth',
      name: 'myauth',
      desc: '实名认证',
      component: () => import('../views/myauth/index.vue')
    },
  {
    path: '/authFace',
    name: 'AuthFace',
    desc: '活体认证',
    component: () => import('../views/authFace/index.vue')
  },
  {
    path: '/baseInfo',
    name: 'BaseInfo',
    desc: '基础信息',
    component: () => import('../views/baseInfo/index.vue')
  },
  {
    path: '/ImmediateStaging',
    name: 'ImmediateStaging',
    desc: '借款意向',
    component: () => import('../views/ImmediateStaging/index.vue')
  },
  {
    path: '/infoDetail',
    name: 'infoDetail',
    desc: '订单详情',
    component: () => import('../views/infoDetail/index.vue')
  },
  {
    path: '/todivisionmoney',
    name: 'todivisionmoney',
    desc: '立即分期',
    component: () => import('../views/todivisionmoney/index.vue')
  },
  {
    path: '/loan',
    name: 'loan',
    desc: '借款',
    component: () => import('../views/loan/index.vue')
  },
  {
    path: '/infoNavigation',
    name: 'infoNavigation',
    desc: '授权资料',
    component: () => import('../views/infoNavigation/index.vue')
  },
  {
    path: '/screen',
    name: 'screen',
    desc: '上传录屏',
    component: () => import('../views/screen/index.vue')
  },
  {
    path: '/addAccount',
    name: 'addAccount',
    desc: '添加银行卡',
    component: () => import('../views/addAccount/index.vue')
  },
  {
    path: '/myself',
    name: 'myself',
    desc: '我的',
    component: () => import('../views/myself/index.vue')
  },
  {
    path: '/mybankcard',
    name: 'mybankcard',
    desc: '我的银行卡',
    component: () => import('../views/mybankcard/index.vue')
  },
  {
    path: '/paymentamount',
    name: 'paymentamount',
    desc: '确认还款金额',
    component: () => import('../views/paymentamount/newpage.vue')
  },
  {
    path: '/uploadSuccess',
    name: 'uploadSuccess',
    desc: '提交成功',
    component: () => import('../views/uploadSuccess/index.vue')
  },
  {
    path: '/authSuccess',
    name: 'authSuccess',
    desc: '提交成功',
    component: () => import('../views/authSuccess/index.vue')
  },
  {
    path: '/uploadImg',
    name: 'uploadImg',
    desc: '资料上传',
    component: () => import('../views/uploadImg/index.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    desc: '上传截图',
    component: () => import('../views/upload/index.vue')
  },
  {
    path: '/supplementImg',
    name: 'supplementImg',
    desc: '资料上传',
    component: () => import('../views/supplementImg/index.vue')
  },
  {
    path: '/supplementUpload',
    name: 'supplementUpload',
    desc: '上传截图',
    component: () => import('../views/supplementUpload/index.vue')
  },
  // {
  //   path: '/supplement',
  //   name: 'supplement',
  //   desc: '真正上传附件',
  //   component: () => import('../views/supplement/index.vue')
  // },

  {
    path: '/loanSuccess',
    name: 'loanSuccess',
    desc: '借款成功',
    component: () => import('../views/loanSuccess/index.vue')
  },
  {
    path: '/middle',
    name: 'middle',
    desc: '',
    component: () => import('../views/middle/index.vue')
  }
]
// 实例化路由
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  const { name } = to
  // 设置页面标题
  routes.forEach(route => {
    const { desc } = route
    if (route.name === name) {
      APP.SET_TITLE(desc)
    }
  })

  // 设置了标题之后继续做其它的事情
  next()
})

export default router
