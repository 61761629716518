

// 格式化金额，数字
const formatMoney = function (s, n) {
  console.log('formatMoney')
}
// 获取hash路由URL的参数
const GetUrlParams = function (href) {
  const u = href || window.location.href
  const o = {}
  const startNum = u.indexOf('?')
  if (startNum !== -1) {
    const paramsStr = u.substring(startNum + 1)
    if (paramsStr.indexOf('&') !== -1) {
      const cacheArr = paramsStr.split('&')
      cacheArr.forEach((item) => {
        const arr = item.split('=')
        o[arr[0]] = arr[1]
      })
    } else {
      const paramsArr = paramsStr.split('=')
      o[paramsArr[0]] = paramsArr[1]
    }
  }
  return o
}

  //压缩方法
  function dealImage(base64, w, fn,type) {
    console.log('压缩前：',base64.length / 1024);
    let p = new Promise((resolve,reject)=>{
      var newImage = new Image();
      var quality = 0.6; //压缩系数0-1之间
      newImage.src = base64;
      newImage.setAttribute("crossOrigin", "Anonymous"); //url为外域时需要
      var imgWidth, imgHeight;
      newImage.onload = function () {
        imgWidth = this.width;
        imgHeight = this.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = (w * imgHeight) / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = (w * imgWidth) / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
          quality = 0.6;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
        var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
        resolve(base64,type)
      }
    });
    console.log(p,'pppppp');
    return p
      // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
      // while (base64.length / 1024 > 150) {
      // 	quality -= 0.01;
      // 	base64 = canvas.toDataURL("image/jpeg", quality);
      // }
      // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
      // while (base64.length / 1024 < 50) {
      // 	quality += 0.001;
      // 	base64 = canvas.toDataURL("image/jpeg", quality);
      // }
      // const imgArr=[];
      // imgArr.push(base64)

      //必须通过回调函数返回，否则无法及时拿到该值
  }

// 判断对象是否为空
function isEmptyObj (e) {
  let t
  for (t in e) {
    return !1
  }
  return !0
}

// 转化日期
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
const timeDate = Date.prototype.format
// 跳转的公共路径

const routerTag = process.env.VUE_APP_ROUTE

const cbak = function () {
  console.log('cbak')
}

const bindEvent = function (fn) {
  document.addEventListener('McEvent', cbak, true)
}

const EvalEvent = function () {
  document.dispatchEvent('McEvent')
}

const removeEvent = function (fn) {
  document.removeEventListener('McEvent', cbak, true)
}

module.exports = {
  bindEvent,
  EvalEvent,
  removeEvent,
  formatMoney,
  GetUrlParams,
  isEmptyObj,
  timeDate,
  routerTag,
  dealImage
  // pathJump
}



