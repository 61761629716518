/* 请求的接口封装 */

import request from '../utils/Ajax'
// eslint-disable-next-line no-unused-vars
import APP from '../utils/App'
// import request from '../utils/fetch'

export default {
  // 授权手机号 1
  async registerPhone(data) {
    return request.post('login/v1/registerConfirm', data)
  },
  /* 登录 1 */
  async isLogin(data) {
    return request.post('login/v1/register', data)
  },
  // 是否需要签署协议 1
  async issignProtocol(data) {
    return request.post('login/v1/needSignProtocol', data)
  },
  // 获取用户合同未签署
  async getProtocol(data) {
    return request.post('login/v1/getProtocol', data)
  },
  // 协议签署
  async alreadyProtocol(data) {
    return request.post('login/v1/signProtocol', data)
  },
  //  首页 1
  async isUser(data) {
    return request.post('user/v1/home', data)
  },
  // 提额初始化
  async addMoney(data) {
    const creditNo = localStorage.getItem('creditNo')
    return request.get('loan/v1/addLoanInit?creditNo=' + creditNo, data, 'portal')
  },

  /* 上传通讯录 */
  async addressBook(data) {
    return request.post('app/v1/fileReports', data)
  },
  /* 获取osstoken */
  async uploadOss(data) {
    return request.get('common/v1/oss', data, 'portal')
  },
  async uploadOs(data) {
    return request.get('common/v2/oss', data, 'portal')
  },
  // 获取基础信息1
  async baseInfo(data) {
    return request.post('auth/v1/baseInfoConfig', data, 'portal')
  },
  /* 基础信息提交  1*/
  async baseSubmit(data) {
    return request.post('auth/v1/baseInfo', data, 'portal')
  },
  // 获取基础信息（反显）
  async getBaseInfo(data) {
    const userId = localStorage.getItem('userId')
    return request.get('auth/v1/getBaseInfo?userId=' + userId, data, 'portal')
  },
  /* 实名认证  1*/
  async authOcr(data) {
    return request.post('auth/v1/ocrscan', data, 'portal')
  },
  /* 更新实名认证 */
  async updateAuth(data) {
    return request.post('auth/v1/updateRealName', data, 'portal')
  },
  /* 实名认证提交 1 */
  async authSubmit(data) {
    return request.post('auth/v1/realName', data, 'portal')
  },
  /* 获取用户姓名信息 1 */
  async getRealName(data) {
    const userId = localStorage.getItem('userId')
    return request.get('user/v1/info?userId=' + userId, data, 'portal')
  },
  /*获取活体token 1*/
  async getliveToken(data) {
    const userId = localStorage.getItem('userId')
    return request.get('auth/v2/getLiveToken?userId=' + userId, data, 'portal')
  },
  /*活体认证 新接口 1 */
    async liveBody(data) {
      return request.post('auth/v2/seanLivingBody', data, 'portal')
    },
  /* 活体人脸识别 */
  async faceReco(data) {
    return request.post('auth/v2/seanLivingBody', data, 'portal')
  },
  // 获取用户授信状态 1
  async getUserStatus(data) {
    return request.post('auth/v1/getProList', data, 'portal')
  },
  // 扫描bd二维码
  async scanBd(data) {
    return request.post('auth/v1/seanBd', data, 'portal')
  },
  // 重新扫描bd二维码
  async scanBdTwo(data) {
    return request.post('auth/v1/seanAgain', data, 'portal')
  },
  // 获取借款意向单
  async getLoanData(data) {
    return request.post('auth/v1/getLoanConfigIntention', data, 'portal')
  },
  // 借款试算
  async getLoanNum(data) {
    return request.post('auth/v1/loanTrial', data, 'portal')
  },
  // 保存借款意向
  async saveLoan(data) {
    return request.post('auth/v1/saveLoanIntention', data, 'portal')
  },
  // 获取图片资料
  async getPhoto(data) {
    return request.post('auth/v1/getImg', data, 'portal')
  },
  // 获取图片资料详情
  async getPhotoList(data) {
    return request.post('auth/v1/getImaList', data, 'portal')
  },
  // 上传资料图片
  async uploadFile(data) {
    return request.post('auth/v1/imgUpload', data, 'portal')
  },
  // 提交照片资料
  async submitPhoto(data) {
    return request.post('auth/v1/finishImg', data, 'portal')
  },
  //获取oss验签
  // async getOss(data) {
  //   return request.post('common/v1/oss', data, 'portal')
  // },
  // 去授信
  async gotoLoan(data) {
    return request.post('auth/v1/credit', data, 'portal')
  },
  // 提交提额授信
  async subAddMoney(data) {
    const creditNo = localStorage.getItem('creditNo')
    return request.get('auth/v1/addCredit?creditNo=' + creditNo, data, 'portal')
  },
  /* 基础信息初始化 */
  async baseInit(data) {
    return request.get('app/v1/infoInit', data, 'portal')
  },

  /* 首页登录 */
  async login(data) {
    // console.log(data, 'api')
    return request.post('app/v1/loginNoCheck', data, 'portal')
  },

  // 意向单详情
  async intention(data) {
    const creditNo = localStorage.getItem('creditNo')
    return request.get('loan/v1/intention?creditNo=' + creditNo, data, 'portal')
  },
  // 意向单详情
  async intentions(data) {
    const creditNo = localStorage.getItem('creditNo')
    return request.get('loan/v1/orderDetail?creditNo=' + creditNo, data, 'portal')
  },
  //借款试算
  async loanFee(data) {
    return request.post('loan/v1/loanFee', data, 'portal')
  },
  // 查看绑卡支持列表
  async bankCard(data) {
    return request.get('card/v1/cardSupport?bizType=123', data, 'portal')
  },
  // 发送绑卡验证码
  async sendSms(data) {
    return request.post('card/v1/cardSms', data, 'portal')
  },
  // 点击提交发送绑卡信息
  async sendAccountInfo(data) {
    return request.post('card/v1/cardBind', data, 'portal')
  },
  // 点击确认绑卡
  async ensureBind(data) {
    return request.post('card/v1/cardBind', data, 'portal')
  },
  // 查看用户卡列表
  async seecardList(data) {
    return request.post('card/v1/cardList', data, 'portal')
  },
  //用户默认绑卡信息
  async getuserbindInfo(data) {
    const userId = localStorage.getItem('userId')
    return request.get('card/v1/bindInfo?userId=' + userId, data, 'portal')
  },
  // 提交借款意向单信息
  async loanInfo(data) {
    return request.post('loan/v1/loanSubmit', data, 'portal')
  },
  // 协议列表
  async getProtocols(data) {
    return request.post('common/v1/contract', data, 'portal')
  },
  // 获取借款详情单信息
  async getloanInfo(data) {
    // const loanNo = localStorage.getItem('loanNo')
    return request.get('loan/v1/loanDetail', data, 'portal')
  },
  // 还款试算
  async backFee(data) {
    return request.post('back/v1/backFee', data, 'portal')
  },
  // 还款发送验证码
  async backSms(data) {
    return request.post('back/v1/backSms', data, 'portal')
  },
  // 还款
  async backMoney(data) {
    return request.post('back/v1/back', data, 'portal')
  },
  // 我的银行卡
  async getMycardList(data) {
    const userId = localStorage.getItem('userId')
    return request.get('card/v1/myCardList?userId=' + userId, data, 'portal')
  }

  /* 贷款授信详情   */
  // async indexDes(data) {
  //   const idNO = window.localStorage.getItem("appNo");
  //   return request.get(`app/v1/loan/status/${idNO}`, data, "portal");
  // },
  /* 贷超产品详情 */
  // async product(data) {
  //   const idNO = window.localStorage.getItem("appNo");
  //   return request.get(`app/v1/loanDetail/${idNO}`, data, "portal");
  // },
  /* 信息授权提交 */
  // async productCredit(data) {
  //   return request.post("app/v1/credit", data, "portal");
  // },
  /* 借款页面初始化 */
  // async loanInits(data) {
  //   const orderNo = window.localStorage.getItem("orderNo");
  //   const idNO = window.localStorage.getItem("appNo");
  //   return request.get(
  //     `app/v1/loanDetail/${idNO}/${orderNo}/init`,
  //     data,
  //     "portal"
  //   );
  // },
  /* 提交借款 */
  // async loanSubmit(data) {
  //   return request.post("app/v1/loan", data, "portal");
  // },
  /* 查询还款计划 */
  // async loanFee(data) {
  //   return request.post("app/v1/loanPlan", data, "portal");
  // },
  /* 贷超列表 */
  // async loanList(data) {
  //   return request.post("app/v1/loanList", data, "portal");
  // },
  /* 借款记录列表 */
  // async loanRecord(data) {
  //   return request.post("app/v1/loanRecode", data, "portal");
  // },
  /* 待还账单列表 */
  // async bill(data) {
  //   return request.get("app/v1/bills", data, "portal");
  // },
  /* 账单详情 */
  // async billDetail(data) {
  //   const loanNo = window.localStorage.getItem("loanNo");
  //   return request.get(`app/v1/bills/${loanNo}`, data, "portal");
  // },
  /* 还款短信 */
  // async paySmsCode(data) {
  //   return request.post("app/v1/repaySms", data, "portal");
  // },
  /* 还款 */
  // async paySubmit(data) {
  //   return request.post("app/v1/repay", data, "portal");
  // },
  /* 提前还款试算(全部结清) */
  // async payAll(data) {
  //   return request.post("app/v1/calc", data, "portal");
  // },
  /* 获取贷后协议列表 */
  // async payProtol(data) {
  //   return request.get(`app/v1/loanProtocol/${loanNo}`, data,'portal')
  // },
  /* 获取支持银行卡列表bankSupport */
  // async bankSupport(data) {
  //   const orderNo = window.localStorage.getItem("orderNo");
  //   const idNO = window.localStorage.getItem("appNo");
  //   return request.get(
  //     `app/v1/supportCards/${idNO}/${orderNo}`,
  //     data,
  //     "portal"
  //   );
  // },
  /* 银行卡信息初始化 */
  // async bankInit(data) {
  //   return request.get("app/v1/user", data, "portal");
  // },
  /* 银行卡列表 */
  // async bankLists(data) {
  //   const orderNo = window.localStorage.getItem("orderNo");
  //   const idNO = window.localStorage.getItem("appNo");
  //   return request.get(`app/v1/bankCards/${idNO}/${orderNo}`, data, "portal");
  // },
  /* 绑定银行卡发送验证码 */
  // async bankSms(data) {
  //   return request.post("app/v1/bankSendCode", data, "portal");
  // },
  /* 绑定银行卡 */
  // async bankBind(data) {
  //   return request.post("app/v1/saveCard", data, "portal");
  // },
  /* 意见反馈 投诉中心提交 */
  // async feedback(data) {
  //   return request.post("user/v1/addFeed", data, "portal");
  // },
  // 意见反馈 投诉中心 上传oss
  // async uploadImg(data) {
  //   return request.get("config/v1/oss", data, "portal");
  // }
}
